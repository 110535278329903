import React from 'react'
import {
  ContentElements,
  ProductList,
  ProductListBanner,
  Breadcrumbs,
} from '../../patterns'
import {
  useGlobalData,
  submitProductListForms,
  resetAllProductListFilters,
  useFeatureFlags,
} from '../../utils'

export default function ProductListWithProps() {
  const { pageData, params = {} } = useGlobalData()
  const { isFeatureEnabled } = useFeatureFlags()

  // restParams = sorting, pagination, ...
  const { filter, ...restParams } = params
  const categoryId = pageData?.data?.self?.id
  let products = pageData.data.product.items
  products = products?.filter((product) => {
    const productField = product.fields
    if (productField.visible_in_catalog == true) return product
  })

  let banners = pageData.data.banners
  banners.sort(function (a, b) {
    return parseInt(a.position) - parseInt(b.position)
  })

  const aggregations = pageData.data.product.aggregations
  const totalProductCount = pageData.data.product.total
  const categoryData = pageData.data.self
  // Remove first item here, as 'Breadcrumbs' renders its own first item
  const breadcrumbs = pageData.data.self.navigation?.breadcrumb?.slice(1)
  const productListProps = {
    products: products,
    aggregations,
    submitForms: async (options = {}) => {
      const { resetPagination = false } = options

      await submitProductListForms({
        aggregations,
        resetPagination,
        count: restParams?.count,
      })
    },
    resetAllFilters: resetAllProductListFilters,
    queryParams: restParams,
    totalProductCount,
    categoryData: categoryData,
  }
  return (
    <>
      {isFeatureEnabled('category.banners') && (
        <ProductListBanner banners={banners} categoryId={categoryId} />
      )}
      <ContentElements
        elements={pageData.data.self.contentElements?.top?.elements}
      />
      <Breadcrumbs items={breadcrumbs} />
      <ProductList {...productListProps} />
      <ContentElements
        elements={pageData.data.self.contentElements?.bottom?.elements}
      />
    </>
  )
}
